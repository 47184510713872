import {React, useEffect} from "react";
import { Link, Outlet, useLocation } from "react-router-dom"; // Importamos useLocation para obtener la ruta actual
import Header from "../../components/Header/Header"; // Importamos el Header
import Slider from "../../components/Slider"; // Importamos el Slider
import Footer from "../../components/Footer/Footer";
import "./Colegio.css"; // Importamos los estilos específicos para Colegio
// Importar las imágenes del Slider
import slide1 from '../../assets/images/slide2.png'
import slide2 from '../../assets/images/slide1.png'
import slide3 from '../../assets/images/slide3.png'
import slide4 from '../../assets/images/slide4.png'

// Importar iconos
import icono2 from '../../assets/images/cuadernos-icon.png'
import icono4 from '../../assets/images/premio-icon.png'
import icono5 from '../../assets/images/uniforme.png'
import icono6 from '../../assets/images/utiles.png'
import icono7 from '../../assets/images/furgon.png'

import image1 from '../../assets/images/colegio1.jpg'
import image2 from '../../assets/images/colegio3.jpg'
import image3 from '../../assets/images/imagec.jpg'
import image4 from '../../assets/images/colegio4.jpg'

function Colegio() {

  
  const location = useLocation(); // Obtenemos la ubicación actual
  // Aquí determinamos si estamos en la página principal '/colegio'
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // El array vacío indica que el efecto se ejecutará solo una vez después del montaje

  const isColegioPage = location.pathname === "/colegio";

  // Array de imágenes para el Slider
  const images = [
    { src: slide1, url: "./informacion" }, // Redirige a '/pagina1'
    { src: slide2, url: "./admision" }, // Redirige a '/pagina2'
    { src: slide3 }, // No tiene URL, no redirige
    { src: slide4,}  // Redirige a '/pagina4'
  ];
  
  return (
    <div className="colegio-container">
      {/* Solo mostramos el Header y el Slider en '/colegio' */}
      <Header />
      {isColegioPage && (
        <>
          <Slider images={images} />
                <div className="divisions-container">
        <h2 className="divisions-title">Sobre nosotros</h2>
        <div className="division-cards">
          <div className="division-card">
            <img src={image1} alt="Nosotros" />
            <div className="card-info">
              <h3>Escuela básica</h3>
              <p>Cursos desde pre-kinder a 7mo básico</p>
            </div>
          </div>
          <div className="division-card">
            <img src={image2} alt="SIMCE" />
            <div className="card-info">
              <h3>Excelencia</h3>
              <p>Contamos con destacados puntajes en SIMCE</p>
            </div>
          </div>
          <div className="division-card">
            <img src={image3} alt="Año" />
            <div className="card-info">
              <h3>1986</h3>
              <p>Año de inaguración</p>
            </div>
          </div>
          <div className="division-card">
            <img src={image4} alt="Proyectos" />
            <div className="card-info">
              <h3>Proyectos</h3>
              <p>Remodelación en proceso, próximamente contaremos con 8vo básico</p>
            </div>
          </div>
        </div>
      </div>

      <div className="info-container">
  
  <Link to="./pagos" className="info-item">
    <img src={icono2} alt="Semestres" className="info-icon" />
    <h2 className="info-title">Pagos online</h2>
    <p className="info-description">Matrícula, certificados y varios.</p>
  </Link>
  
  <Link to="./certificados" className="info-item">
    <img src={icono4} alt="Nacionalidades" className="info-icon" />
    <h2 className="info-title">Certificados</h2>
    <p className="info-description">Notas, Matrícula, etc.</p>
  </Link>
  
  <Link to="./uniformes" className="info-item">
    <img src={icono5} alt="Uniformes" className="info-icon" />
    <h2 className="info-title">Uniformes</h2>
    <p className="info-description">Dónde comprar</p>
  </Link>
  
  <Link to="./informacion" className="info-item">
    <img src={icono6} alt="Utiles" className="info-icon" />
    <h2 className="info-title">Listas de útiles</h2>
    <p className="info-description">Descargar aquí</p>
  </Link>
  
  <Link to="./transporte" className="info-item">
    <img src={icono7} alt="Transporte" className="info-icon" />
    <h2 className="info-title">Transporte</h2>
    <p className="info-description">Contactos de furgón escolar</p>
  </Link>
</div>

        </>
      )}
      {/* El Outlet renderiza las subpáginas como Matrícula, Nosotros, Postulación */}
      <div className="colegio-content">
        <Outlet /> {/* Aquí se cargan los componentes de las subpáginas */}
      </div>
      <Footer />
    </div>
  );
}

export default Colegio;

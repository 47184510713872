import React from 'react';
import "./SubPage.css"
const Transporte = () => {
  return (
    <div>
      <div className="image-overlay-container">
        <h1 className="overlay-title">Transporte escolar</h1>
      </div>

      <div className="container-pages">
      {/* Columna Izquierda */}
      <div className="container-pages-left">
        <h2>Datos de contacto</h2>
      </div>

      {/* Columna Derecha */}
      <div className="container-pages-right">
        <div className="container-pages-description">
          <h3>Furgones</h3>
          <p>
            <ul>
                <li><strong>Tía Carmen: </strong> +56993604493</li>
                <li><strong>Tía Patricia: </strong> +56973093228</li>
                <li><strong>Tía Verito: </strong> +56981207847</li>
                <li><strong>Tío Sergio: </strong> +56998984214</li>
            </ul>
          </p>
        </div>      
      </div>
    </div>
    </div>
  );
};

export default Transporte;

import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-colegio.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Detectar si la pantalla es pequeña
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Detecta el tamaño inicial
    window.addEventListener("resize", handleResize); // Detecta cambios de tamaño

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = (e) => {
    e.stopPropagation(); // Evita cerrar el menú principal
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const closeMenus = () => {
    setIsMenuOpen(false);
    setIsSubMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo del colegio" />
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`menu ${isMenuOpen ? "active" : ""}`}>
        <ul>
          <li onClick={closeMenus}>
            <Link to="/colegio">Inicio</Link>
          </li>
          <li onClick={closeMenus}>
            <Link to="/Colegio/admision">Admisión</Link>
          </li>
          <li onClick={closeMenus}>
            <Link to="/Colegio/nosotros">Nosotros</Link>
          </li>
          <li
            className="submenu-container"
            onMouseEnter={() => !isMobile && setIsSubMenuOpen(true)} // Solo abre con hover si no es móvil
            onMouseLeave={() => !isMobile && setIsSubMenuOpen(false)} // Solo cierra con hover si no es móvil
          >
            <span
              className="submenu-title"
              onClick={isMobile ? toggleSubMenu : undefined} // Solo abre/cierra con clic si es móvil
            >
              Información 2025
            </span>
            <ul className={`submenu ${isSubMenuOpen ? "open" : ""}`}>
              <li onClick={closeMenus}>
                <Link to="/colegio/informacion">General</Link>
              </li>
              <li onClick={closeMenus}>
                <Link to="/colegio/transporte">Transporte</Link>
              </li>
              <li onClick={closeMenus}>
                <Link to="/colegio/uniformes">Uniformes</Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

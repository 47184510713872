import React from 'react';
import "./SubPage.css"
const Informacion = () => {
  return (
    <div>
      <div className="image-overlay-container">
        <h1 className="overlay-title">Año escolar 2025</h1>
      </div>

      <div className="container-pages">
      {/* Columna Izquierda */}
      <div className="container-pages-left">
        <h2>Dirección académica</h2>
      </div>

      {/* Columna Derecha */}
      <div className="container-pages-right">
        <div className="container-pages-description">
          <h3>Listas de útiles</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <p>Descuentos en tienda SM: 50% en textos de estudio y 30% en lectura complementaria <strong>hasta el día 15 de enero</strong></p>

          {/* Botón */}
          <a
            href="/docs/PLAYGROUP2025.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Playgroup
          </a>
          <a
            href="/docs/PREKINDER2025.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            PreKinder
          </a>
          <a
            href="/docs/KINDER2025.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Kinder
          </a>
          <a
            href="/docs/PRIMERO2025.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Primero básico
          </a>
          <a
            href="/docs/SEGUNDO2025.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Segundo básico
          </a>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <a
            href="/docs/TERCERO2025.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Tercero básico
          </a>
          <a
            href="/docs/CUARTO2025.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Cuarto básico
          </a>
          <a
            href="/docs/QUINTO2025.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Quinto básico
          </a>
          <a
            href="/docs/SEXTO2025.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Sexto básico
          </a>
          <a
            href="/docs/SEPTIMO2025.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Séptimo básico
          </a>
          </div>
        </div>
        <div className="container-pages-details">
          <h4>Contacto de profesores</h4>
          <p>
            El medio formal de comunicación con los profesores es a través del correo electrónico institucional:
            <br/>
            <a
            href="/docs/Contacto-Profesores.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Correo de profesores
          </a>  
          </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Informacion;

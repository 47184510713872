import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Colegio from "./pages/Colegio/Colegio";
import Matricula from "./pages/Colegio/Matricula";
import Nosotros from "./pages/Colegio/Nosotros";
import Admision from "./pages/Colegio/Admision";
import Certificados from "./pages/Colegio/Certificados";
import Jardin from "./pages/Jardin/Jardin";
import MatriculaJardin from "./pages/Jardin/MatriculaJardin";
import NosotrosJardin from "./pages/Jardin/NosotrosJardin";
import Pagos from "./pages/Colegio/Pagos";
import PagosJardin from "./pages/Jardin/PagosJardin";
import Uniformes from "./pages/Colegio/Uniformes";
import Transporte from "./pages/Colegio/Transporte";
import Informacion from "./pages/Colegio/Informacion";


import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        {/* Página de inicio */}
        <Route path="/" element={<Home />} />

        {/* Página Colegio con Header y Slider */}
        <Route path="/colegio" element={<Colegio />}>
          <Route path="matricula" element={<Matricula />} />
          <Route path="nosotros" element={<Nosotros />} />
          <Route path="admision" element={<Admision />} />
          <Route path="certificados" element={<Certificados />}/>
          <Route path="pagos" element = {<Pagos />}/>
          <Route path="uniformes" element = {<Uniformes />}/>
          <Route path="transporte" element = {<Transporte />} />
          <Route path="informacion" element = {<Informacion />} />
        </Route>

        {/* Página Jardin sin Header ni Slider */}
        <Route path="/jardin" element={<Jardin />}>
          <Route path="matriculaJardin" element={<MatriculaJardin />} />
          <Route path="nosotros" element={<NosotrosJardin />} />
          <Route path="pagosJardin" element={<PagosJardin/>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

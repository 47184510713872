import React from 'react';
import "./SubPage.css"
const Uniformes = () => {
  return (
    <div>
      <div className="image-overlay-container">
        <h1 className="overlay-title">Uniforme escolar</h1>
      </div>

      <div className="container-pages">
      {/* Columna Izquierda */}
      <div className="container-pages-left">
        <h2>Información</h2>
        <p>
          Dirección: Pasaje palena 5756, Conchalí
          <br></br>
       </p>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.9371562132837!2d-70.67435652404251!3d-33.3727066734204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c6f836c45371%3A0x4036cedd196bcd14!2sPalena%205756%2C%208560361%20Santiago%2C%20Conchal%C3%AD%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1735405280062!5m2!1ses-419!2scl" 
          width="400" 
          height="300" 
          style={{ border: '0' }} 
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade"
          title='Ubicación'>
          </iframe>

      </div>

      {/* Columna Derecha */}
      <div className="container-pages-right">
        <div className="container-pages-description">
          <h3>Contacto</h3>
          <p>

          </p>
          <ul>
            <li> <strong>Horario de atención:</strong> Lunes a viernes, 09:00 a 13:00 hrs y 16:00 a 20:00 hrs.</li>
            <li> <strong>Enero:</strong> Solo consultas generales y de tallas</li>
            <li><strong>Horario de atención febrero :</strong> Lunes a viernes, 09:00 a 13:00 hrs y 16:00 a 20:00 hrs. Sábado de 10:00 a 14:00 hrs.</li>
            <li><strong>Whatsapp:</strong> +56939094679</li>
            <li><strong>Correo electrónico: </strong> x._marta@gmail.com</li>
          </ul>
          {/* Mapa */}         
        </div>
        <div className="container-pages-details">
          <h4>Detalles</h4>
          <p>
            Durante todo el año escolar está permitido el uso de buzo diario para mayor cómodidad
          </p>
          <ul>
            <li><strong>Obligatorio: </strong> Polera, short o falda institucional, chaleco o polerón y zapatillas blancas.</li>
            <li><strong>Opcional: </strong> Accesorios azules</li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Uniformes;
